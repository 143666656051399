@import '../../variables.scss';

.user-summary-page {

    $col-4-width: 32.4%;
    $col-3-width: 23.9%;
    $col-2-width: 15.5%;

    width: 95%;
    max-width: 1790px;
    margin: 0 auto;

    .tab-container {
        border-bottom: 10px solid $color1;
        margin: 10px auto 50px;

        .high-contrast-mode & {
            border-color: #ffff03;
        }
    }

    .tabs {
        display: flex;
        width: 100%;

        align-items: flex-end;

        .tab {
            flex: 0 0 265px;
            height: 4.75rem;

            display: flex;
            align-items: flex-end;

            margin-right: 15px;

            button {
                width: 100%;

                font-size: 1.2rem;
                font-weight: bold;
                font-family: 'Raleway', sans-serif;

                border: solid 3px $color1;
                border-bottom: none;
                background: white;

                height: 75%;
                border-radius: 15px 15px 0 0;

                cursor: pointer;

                &.selected,
                &:hover {
                    height: 100%;
                    background: $color1;
                    color: white;

                    .high-contrast-mode & {
                        background: #ffff03 !important;
                        color: black !important;
                    }
                }

                transition: .2s;

                .high-contrast-mode & {
                    border-color: #ffff03;
                }
            }
        }
    }

    @media (max-width: 600px) {
        .tabs {
            justify-content: space-between;

            .tab {
                flex-basis: 48%;
                margin: 0;
            }
        }
    }
}
