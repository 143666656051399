@import "./variables.scss";
@import "./contrast.scss";

@import-normalize;

html {
    font-size: 18px;
}

body {
    font-family: "Lato", sans-serif;
    min-height: 100vh;
}

#root {
    max-width: 1920px;
    width: 100%;
    min-height: 100vh;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: stretch;
}

html,
body,
div,
h1 {
    margin: 0;
    padding: 0;
}

*:focus {
    outline-offset: 3px;
    outline-style: solid;
    outline-width: thick;
}

p {
    line-height: 1.5em;
}

.top-gfx {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    padding-top: 25%;
    background: url("./assets/top-gfx.png") 50% 50% no-repeat;
    background-size: 100% auto;
}

.summary-top-gfx {
    width: 100%;
    max-width: 1736px;
    padding-top: 17%;
    margin: 0 auto;

    background: url('./assets/top-summary.png') 50% 0 no-repeat;
    background-size: 100% auto;
}

.debug {
    margin: 1em;
    background: #c1c1c1;
    border: 1px dotted black;
    padding: 0.5em;
}

button {
    font-family: "Lato", sans-serif;
    font-size: 1rem;
}

.button {
    display: inline-block;
    padding: 20px 80px;
    text-align: center;
    font-weight: bold;

    background: $color1;
    border: 3px solid $color1;
    border-radius: 13px;

    transition: 0.2s;

    color: white;
    text-decoration: none;
    font-family: "Raleway", sans-serif;
    font-size: 1.33rem;

    &:hover {
        background: white;
        color: $color1;
        cursor: pointer;
    }

    &.disabled,
    &:disabled {
        border-color: $color2;
        color: $color2;
        background: white;
        cursor: default !important;
    }

    .high-contrast-mode &:hover {
        color: black !important;
        background-color: #ffff03 !important;
    }

    .high-contrast-mode &:disabled {
        color: $color2 !important;
        background-color: black !important;
    }

    &.btn-inverted {
        background: white;
        color: $color1;

        &:hover {
            background: $color1;
            border: 3px solid $color1;
            cursor: pointer;
            color: white;
        }

        &.disabled,
        &:disabled {
            border-color: $color2;
            color: $color2;
            background: white;
            cursor: default !important;
        }
    }

    &.btn-small {
        font-size: 1rem;
        padding: 10px 40px;

    }
}

.rules-form {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    margin: 0 auto;

    & > * {
        flex: 0 0 30%;
    }

    textarea {
        height: 100px;
    }
}

.page-container {
    position: relative;
    flex: 1 0 50vh;

    display: flex;
    flex-direction: column;

    & > * {
        flex: 1 1 100%;
    }
}

.heading-1 {
    color: $header-color;
    font-size: 4.5rem;
    text-align: center;
    font-family: "Raleway", sans-serif;
}

.heading-2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.heading-3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.heading-decor {
    position: relative;

    font-size: 1.2rem;
    font-family: "Raleway", sans-serif;

    padding-left: 1.5%;

    padding-bottom: 22px;

    border-bottom: 1px solid $color1;

    &:after {
        content: "";
        background: $color1;

        position: absolute;
        left: 0;
        bottom: 0;

        height: 10px;
        width: 23.9%;

        .high-contrast-mode & {
            background: #ffff03 !important;
        }
    }
}

.error-dialog {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 2rem 0;
    margin: 1rem 0;
    border: 3px solid #B2182B;
    border-radius: 2rem;

    .heading-error {
        display: inline-block;
        color: #B2182B
    }

    .icon {
        height: 6rem;
        width: 6rem;
        background: url(./assets/icons/error.svg) 50% 50% no-repeat;
        background-size: cover;
        margin: 0 0 1rem 0;
    }

    .button {
        background-color: #B2182B;
        border-color: #B2182B;
        max-width: 90%;

        &:hover {
            background-color: white;
            color: #B2182B;
        }
    }

}

.screen-reader-only {
    position: fixed !important;
    top: -9999px;
}

@media (max-width: 900px) {
    .heading-1 {
        font-size: 2.5rem;
    }
}
