@import "../../variables.scss";

.welcome-page {
    h1 {
        margin: 0.2em 0;
    }

    .content-wrapper {
        max-width: 1565px;
        width: 95%;
        margin: 0 auto;
    }

    nav {
        max-width: 920px;
        width: 95%;
        margin: 0 auto;

        display: flex;

        flex-direction: row;

        .left,
        .right {
            flex: 0 0 50%;
        }

        .right {
            display: flex;
            justify-content: flex-end;
        }
    }

    .welcome-text {
        margin: 3em 0;

        text-align: center;
    }

    .teacher-menu {
        width: 1720px;
        max-width: 95%;

        margin: 75px auto;

        .links {
            display: flex;
            justify-content: center;

            .button {
                margin-right: 10px;
            }
        }

        h2 {
            position: relative;

            font-size: 1.2rem;
            font-family: "Raleway", sans-serif;

            padding-left: 1.5%;

            padding-bottom: 22px;

            border-bottom: 1px solid $color1;

            &:after {
                content: "";
                background: $color1;

                position: absolute;
                left: 0;
                bottom: 0;

                height: 10px;
                width: 23.9%;
            }
        }
    }

    .logout {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1em;
    }

    @media (max-width: 900px) {
        nav {
            flex-direction: column;

            .left,
            .right {
                flex-basis: 100%;
                margin: 15px 0;

                display: flex;
            }

            a {
                flex: 1 1 100%;
            }
        }

        .teacher-menu {
            .links {
                flex-direction: column;

                .button {
                    margin: 5px 0;
                }
            }

            h2:after {
                width: 50%;
            }
        }
    }
}
