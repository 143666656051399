@import "../../variables.scss";

.summary-tab {
    position: relative;
    width: 100%;

    $col-4-width: 32.4%;
    $col-3-width: 23.9%;

    .points {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        justify-content: space-between;

        margin: 46px 0;

        .point-info {
            flex: 0 1 $col-4-width;
            line-height: 3em;
            margin-bottom: 0.5em;

            display: flex;
            flex-direction: column;
            align-items: stretch;

            .wrapper {
                $h: 2.4em;

                display: flex;

                height: $h;
                width: 80%;

                &.cat-1 .category-name {
                    background: url("../../assets/icons/icon-atmosf.svg") 0 50% no-repeat;
                    background-size: auto 100%;

                    .high-contrast-mode & {
                        background-image: url("../../assets/icons/contrast/icon-atmosf.svg");
                    }
                }

                &.cat-2 .category-name {
                    background: url("../../assets/icons/icon-profesjonal.svg") 0 50% no-repeat;
                    background-size: auto 100%;

                    .high-contrast-mode & {
                        background-image: url("../../assets/icons/contrast/icon-profesjonal.svg");
                    }
                }

                &.cat-3 .category-name {
                    background: url("../../assets/icons/icon-muzeum.svg") 0 50% no-repeat;
                    background-size: auto 100%;

                    .high-contrast-mode & {
                        background-image: url("../../assets/icons/contrast/icon-muzeum.svg");
                    }
                }

                .category-container {
                    padding: 0;
                    margin: 0;
                    line-height: $h;
                    width: 100%;

                    color: $color1;
                    font-weight: bold;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .category-score {
                    display: flex;
                    flex: 0 0 14.2em;
                }

                .category-name {
                    flex: 1 1 100%;
                    padding: 0 0 0 $h * 1.25;
                }

                .points-numeric {
                    display: none;
                }

                .star {
                    display: inline-block;
                    width: 2em;
                    height: 2em;
                    margin: 0 0.82em 0 0;

                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                    background-size: contain;

                    &.star-0 {
                        background-image: url("../../assets/icons/star-0.png");
                    }

                    &.star-1 {
                        background-image: url("../../assets/icons/star-1.png");
                    }
                }
            }
        }

        @media (max-width: 1350px) {
            flex-direction: column;
            width: 50%;
            margin: 2.5em auto;

            .point-info {
                margin-bottom: 1em;

                .wrapper {
                    width: 100%;
                }
            }
        }

        @media (max-width: 900px) {
            flex-direction: column;
            width: 75%;
            margin: 2.5em auto;
        }

        @media (max-width: 600px) {
            flex-direction: column;
            width: 100%;

            .point-info {
                .wrapper {
                    height: auto;
                    .category-container {
                        flex-direction: column;
                    }

                    .category-name {
                        margin-bottom: .5em;
                    }

                    .category-score {
                        flex: 1 1 auto;
                    }
                }
            }
        }
    }

    .rules {
        padding: 30px 0;

        ul {
            list-style: none;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            padding: 0;
            margin: 0;

            li {
                flex: 0 0 $col-4-width;

                background: $color3;
                border-radius: 15px;

                margin: 0 0 20px 0;

                .wrapper {
                    padding: 23px 32px;

                    p {
                        margin: 0;
                        line-height: 1.3em;
                    }
                }
            }

            h3 {
                background: url("../../assets/icons/icon-zasady.svg") 0em 50% no-repeat;
                background-size: auto 100%;

                font-size: 1rem;
                font-family: "Raleway", sans-serif;

                line-height: 2em;
                margin: 0 0 13px;
                padding-left: 2.5em;

                .high-contrast-mode & {
                    background-image: url("../../assets/icons/contrast/icon-zasady.svg");
                }
            }
        }
    }

    .choises {
        padding: 30px 0;

        ul {
            list-style: none;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            justify-content: space-between;

            padding: 0;
            margin: 0;
        }

        li {
            flex: 0 0 $col-4-width;

            border-radius: 20px;
            border: 3px solid $color1;

            margin: 0 0 20px 0;

            .high-contrast-mode & {
                border-color: #ffff03 !important;
            }
        }

        h3 {
            background: url("../../assets/icons/icon-data.svg") 0em 50% no-repeat;
            background-size: auto 100%;

            font-size: 1rem;
            font-family: "Raleway", sans-serif;

            line-height: 2em;
            margin: 0 0 13px;
            padding-left: 2.5em;

            .high-contrast-mode & {
                background-image: url("../../assets/icons/contrast/icon-data.svg");
            }
        }

        .choise {
            padding: 23px 32px;
        }
    }

    .actions {
        display: flex;
        justify-content: center;
    }

    @media (max-width: 900px) {
        h2 {
            &:after {
                width: 100%;
            }
        }

        .rules {
            ul {
                flex-direction: column;
            }
        }

        .choises {
            ul {
                flex-direction: column;
            }
        }
    }
}
