@import "../../variables.scss";

.game-page {
    .popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 90vh;

        padding: 5vh 0;

        background: rgba(0, 0, 0, 0.5);

        display: flex;
        justify-content: center;
        align-items: center;

        z-index: 10;

        .high-contrast-mode & {
            background: rgba(0, 0, 0, 0.5) !important;
        }

        .container {
            background: white;
            border-radius: 1em;

            position: relative;

            max-width: 30rem;
            width: 95%;

            max-height: 90vh;

            display: flex;

            border: solid 3px $color1;
            pointer-events: all;

            .high-contrast-mode & {
                border-color: #ffff03;
                background: black !important;
            }
        }

        .wrapper {
            padding: 5em 2em 1em;
            position: relative;
            overflow: hidden;
            display: flex;

            .high-contrast-mode & {
                background: transparent !important;
            }
        }

        .content {
            padding: 100px 0 40px;
            background: url("../../assets/icons/bell.png") 50% 0 no-repeat;

            .high-contrast-mode & {
                background-image: url("../../assets/icons/contrast/bell.png");
            }
        }

        .scroll-box {
            overflow-y: auto;
        }

        .buttons {
            display: flex;
            justify-content: center;
            margin: 55px 0 57px;
        }

        .points {
            display: flex;
            margin: 0 auto;
            justify-content: space-between;
            color: $color1;
            font-weight: bold;

            flex-wrap: wrap;
            justify-content: center;

            p {
                margin: 10px;

                strong {
                    padding-right: 2.2em;
                    line-height: 1.7em;
                    font-size: 1.33rem;
                    display: inline-block;
                    margin-right: 9px;
                }

                &.points-1 strong {
                    background: url("../../assets/icons/icon-atmosf.svg") right 50% no-repeat;
                    background-position: right 1px top;
                    background-size: auto 100%;

                    .high-contrast-mode & {
                        background-image: url("../../assets/icons/contrast/icon-atmosf.svg");
                    }
                }

                &.points-2 strong {
                    background: url("../../assets/icons/icon-profesjonal.svg") right 50% no-repeat;
                    background-size: auto 100%;

                    .high-contrast-mode & {
                        background-image: url("../../assets/icons/contrast/icon-profesjonal.svg");
                    }
                }

                &.points-3 strong {
                    background: url("../../assets/icons/icon-muzeum.svg") right 50% no-repeat;
                    background-size: auto 100%;

                    .high-contrast-mode & {
                        background-image: url("../../assets/icons/contrast/icon-muzeum.svg");
                    }
                }
            }
        }

        .audio-player {
            position: absolute;
            top: 15px;
            left: 25px;

            button {
                line-height: 2.5em;
            }
        }

        .button {
            width: 290px;
        }

        @media (max-width: 600px) {
            .content {
                text-align: center;
            }

            .wrapper {
                padding: 3em 1em 1em;
            }

            .buttons {
                margin-bottom: 0;
            }
            .button {
                width: 100%;
            }
        }
    }
}
