.step-video {
    width: 100%;
    padding-top: 100%;

    position: relative;

    video {
        position: absolute;
        display: block;

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        width: 100%;
        height: 100%;
    }
}
