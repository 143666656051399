.step-template {
    display: flex;
    flex-direction: row;

    width: 95%;
    max-width: 1790px;
    margin: 0 auto;

    justify-content: space-between;
    align-items: stretch;

    padding-top: 52px;

    .image-column {
        flex: 0 0 49%;

        display: flex;
        flex-direction: column;

        justify-content: center;

        .img {
            width: 100%;
            // background-color: black;
            padding-top: 100%;
            background-size: 100% auto;
            background-repeat: no-repeat;
        }
    }

    .content-column {
        flex: 0 0 45.15%;
        position: relative;
    }

    @media (max-width: 900px) {
        flex-direction: column;
        align-items: stretch;

        padding-top: 15px;
    }
}
