.azure-login {
    h1 {
        margin: 0.2em 0;
    }

    .content-wrapper {
        max-width: 1565px;
        width: 95%;
        margin: 0 auto;
    }

    .welcome-text {
        margin: 3em 0;

        text-align: center;
    }

    nav {
        max-width: 920px;
        width: 95%;
        margin: 0 auto;

        display: flex;

        flex-direction: row;
        justify-content: center;
    }
}
