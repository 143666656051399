.summary-page {

    width: 95%;
    max-width: 1790px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;

    .summary-top-gfx {
        width: 100%;
        max-width: 1736px;
        padding-top: 16%;
        margin: 0 auto;

        background: url('../../assets/top-summary.png') 50% 0 no-repeat;
        background-size: 100% auto;
    }

    .summary-tab {
        flex: 1 1 100%;
    }

    .actions {
        display: flex;
        justify-content: center;

        margin: 20px 0 75px;
    }
}
