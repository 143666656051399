@import '../../../variables.scss';

.class-list-page {
    .content {
        width: 95%;
        max-width: 1400px;
        margin: 0 auto;
        position: relative;
    }

    table {
        border-collapse: collapse;
        width: 100%;

        thead {
            tr {
                font-weight: bold;
                border-bottom: 5px solid $color1;

                td {
                    &.raport-1,
                    &.raport-2 {
                        border-left: 3px solid $color1;
                        text-align: center;
                    }
                }
            }
        }

        tbody {
            td {
                height: 2em;
                border-bottom: 1px dotted $color1;

                &.raport-1,
                &.raport-2 {
                    border-left: 3px solid $color1;
                    text-align: center;
                }
            }
        }
    }
}
