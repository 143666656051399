@import "../../../variables.scss";

.rules-list-page {
    $col-4-width: 32.4%;
    $col-3-width: 23.9%;

    .content {
        width: 95%;
        max-width: 1750px;
        margin: 0 auto;
    }

    .loader-container {
        position: relative;
    }

    .rules {
        padding: 30px 0;

        ul {
            list-style: none;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            padding: 0;
            margin: 0;

            li {
                flex: 0 0 $col-4-width;

                background: $color3;
                border-radius: 15px;

                margin: 0 0 20px 0;

                .wrapper {
                    padding: 23px 32px;

                    p {
                        margin: 0;
                        line-height: 1.3em;
                    }
                }
            }

            h3 {
                background-size: auto 100%;

                font-size: 1rem;
                font-family: "Raleway", sans-serif;

                line-height: 2em;
                margin: 0 0 13px;
            }
        }
    }



    @media (max-width: 900px) {

        .rules {
            ul {
                flex-direction: column;
            }
        }
    }
}
